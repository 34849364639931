import * as React from 'react';
import { graphql, Link } from 'gatsby';
//components
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colors, device } from '../components/GlobalStyles';
import styled from '@emotion/styled';
import { Technology } from '../components/Technology';
import { Testimonials } from '../components/Testimonials';
import { YellowButton } from '../components/layout/StyledComponents';
import { Seo } from '../components/Seo';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';
//icons
import arrow from '../images/icons/black-arrow-right.svg';

const Section = styled.section`
    padding: 4em 0em;
    margin-top: 2em;
    h1 {
        margin-top: 1em;
        color: ${colors.green};
    }
    button {
        cursor: pointer;
    }

    h4 {
        display: none;
    }
    li {
        font-size: 18px;
    }
    .yellow-box {
        display: none;
        span {
            font-size: 24px;
            font-weight: 700;
        }
        button {
            margin-top: 16px;
            background: ${colors.grey};
            color: ${colors.white};
            border: none;
            margin-left: 40px;
            padding: 1em;
            &:hover {
                background: ${colors.green};
            }
        }
        p {
            margin: 2em 0em 1.5em 0em;
        }
        margin: 0em 3em;
        padding: 1em;
        width: 271px;
        background: ${colors.yellowGradient};
    }

    .sidepanel-list {
        list-style: none;
        display: none;
        li {
            font-weight: 700;
            margin: 2em 0em;
            &:hover {
                color: ${colors.green};
            }
        }
    }
    h2 {
        line-height: 35px;
        margin-top: 1em;
        font-size: 32px;
    }
    /* targets just the call links in markdown. probably more elegant way to target :( */
    p {
        font-size: 19px;
        a {
            color: ${colors.green};
            font-weight: bold;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    padding-top: 10px;
    .image {
        padding: 1em;
    }
    .markdown {
        padding: 0px 3.8vw;
        margin-top: -3em;
    }
    h1 {
        line-height: 58px;
        font-size: 42px;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 48px;
    }
    h2 {
        text-transform: capitalize;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 10px;
    }
    h3 {
        font-size: 28px;
        margin-top: 1em;
        margin-bottom: 0.5em;
        text-transform: capitalize;
        color: ${colors.green};
    }
    @media ${device.tablet} {
        .sidepanel-list {
            cursor: pointer;
            padding: 0px;
            margin-left: 3em;
        }

        .image {
            margin-bottom: 20px;
        }
        margin-top: -8em;
        padding: 2em 3em;
        h2 {
            line-height: 42px;
        }
        .contain {
            width: 983px;
            background: white;
        }
        .flex {
            margin-top: 159px;
            display: flex;
            justify-content: space-around;
        }
        h1 {
            margin-bottom: 24px;
        }
    }
    @media ${device.laptop} {
        padding: 2em 4em;
        article {
            margin-bottom: 4em;
        }
        h1 {
            font-size: 46px;
            margin-bottom: 34px;
        }
        .content {
            max-width: 1440px;
            margin: 24px auto;
        }

        .image {
            max-width: 569px;
            margin: 0 auto;
        }
    }
    @media ${device.laptopL} {
        .sidepanel-list,
        .yellow-box,
        h4 {
            display: block;
        }
        h4 {
            margin-left: 3.8em;
            margin-bottom: 4em;
        }
    }
    .yellow-box {
        p {
            line-height: 24px;
            font-size: 14px;
        }
    }
`;

const Herotext = styled.div`
    margin-top: 2.5em;
    text-align: center;
    h2 {
        font-size: 36px;
        line-height: 46px;
    }
    p {
        font-size: 14px;
        line-height: 28px;
        font-weight: 500;
        padding: 0 1em;
        letter-spacing: 1px;
    }
    @media ${device.tablet} {
        margin-top: 2em;
        h2 {
            font-size: 64px;
            margin: 0 auto;
            width: 50%;
            line-height: 80px;
        }
        p {
            margin: 0 auto;
            margin-top: 1em;
            max-width: 500px;
        }
    }
`;

const BreadCrumbs = styled.div`
    ul {
        display: none;
    }

    @media ${device.tablet} {
        ul {
            cursor: pointer;
            display: flex;
            z-index: 200;
            justify-content: space-around;
            margin: 8em auto -8em;
            font-size: 13px;
            width: 410px;
            li {
                color: grey;
                &:hover {
                    color: black;
                }
            }
        }
    }
    @media ${device.laptopL} {
        ul {
            margin: 10em auto -8em;
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BlogsDetails: Page<any> = ({ data }) => {
    const { mdx } = data;
    const { meta } = mdx.frontmatter;
    return (
        <>
            <BreadCrumbs>
                <ul>
                    <Link to="/">
                        <li style={{ listStyleType: 'none' }}>HOME</li>
                    </Link>
                    <Link to="/blogs/">
                        <li>Blogs</li>
                    </Link>
                    <li style={{ color: 'black' }}>{mdx.frontmatter.type}</li>
                </ul>
            </BreadCrumbs>
            <Seo {...meta} />
            {/* <Herotext>
                <h2>
                    {mdx.frontmatter.type === 'General Dentistry' && (
                        <Herotext>
                            {mdx.frontmatter.type}
                            <p>
                                At Prosthodontics & Implant Therapy, we pride ourselves on treating
                                every member of the family. Family dentistry is focused on
                                preventing potential complications, rather than treating them. If
                                you and your family are in need of professional dental Blogs, we
                                can help!
                            </p>
                        </Herotext>
                    )}
                    {mdx.frontmatter.type === 'Implant-Supported Prostheses' && (
                        <Herotext>
                            {mdx.frontmatter.type}
                            <p>
                                As prosthodontists, we here at Prosthodontics & Implant Therapy, are
                                happy to offer implant-supported prostheses. We believe that dental
                                implants are the ideal solution when it comes to tooth loss.
                            </p>
                        </Herotext>
                    )}

                    {mdx.frontmatter.type === 'Non-Implant Prostheses' && (
                        <Herotext>
                            {mdx.frontmatter.type}
                            <p>
                                While dental implants are often the ideal solution when it comes to
                                teeth replacements, non-implant prostheses can be just as effective.
                                At Prosthodontics & Implant Therapy, we offer non-implant prostheses
                                for a wide variety of reasons. During your initial consultation, we
                                will be able to determine which restoration best suits your needs!
                            </p>
                        </Herotext>
                    )}
                    {mdx.frontmatter.type === 'Implant Prostheses' && (
                        <Herotext>
                            {mdx.frontmatter.type}
                            <p>
                                As prosthodontists, we here at Prosthodontics & Implant Therapy, are
                                happy to offer implant-supported prostheses. We believe that dental
                                implants are the ideal solution when it comes to tooth loss.
                            </p>
                        </Herotext>
                    )}
                </h2>
            </Herotext> */}
            <Section>
                <div className="flex">
                    <div className="panel">
                        <h4>Blogs</h4>
                        <ul className="sidepanel-list">
                            <Link to="/general-dentistry/">
                                <li>General Dentistry</li>
                            </Link>
                            <Link to="/cosmetic-dentistry/">
                                <li>Cosmetic Dentistry</li>
                            </Link>
                            <Link to="/dental-implants/">
                                <li>Dental Implants</li>
                            </Link>
                        </ul>
                        <div className="yellow-box">
                            <span>Get a headstart on your paperwork.</span>

                            <Link to="/patient-resources/forms">
                                <button>NEW PATIENT FORM</button>
                            </Link>
                        </div>
                    </div>
                    <div className="contain">
                        <div style={{ marginTop: '1.5em' }} className="image">
                            <GatsbyImage
                                image={
                                    mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData
                                }
                            />
                        </div>

                        <article className="markdown">
                            <div className="image"></div>
                            <h1>{mdx.frontmatter.title}</h1>
                            <span
                                style={{
                                    position: 'relative',
                                    bottom: '16px',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {mdx.frontmatter.date}
                            </span>
                            <div className="content">
                                {/* was too late to make this elegant given deadline... had we more detailed figma this would not have happened */}
                                <MDXRenderer>{mdx.body}</MDXRenderer>
                            </div>
                        </article>
                    </div>
                </div>
            </Section>
            <Technology />
            <Testimonials />
        </>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                slug
                type
                categories
                date(formatString: "MMM DD, YYYY")
                meta {
                    title
                    description
                }
                featuredImage {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 800
                            aspectRatio: 1.4
                            placeholder: BLURRED
                            quality: 90
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            body
            id
        }
        allMdx(filter: { fields: { sourceInstanceName: { eq: "blogs" } } }) {
            nodes {
                frontmatter {
                    slug
                    title
                    categories
                }
                body
                excerpt
                slug
                id
            }
        }
    }
`;

export default BlogsDetails;

BlogsDetails.Layout = DefaultLayout;
